import React, { useState, useEffect, useContext } from "react";
import { Grid, List, ListItem, Menu, MenuItem, Avatar } from "@material-ui/core";
import { imageBasePath } from "../constants";
import styles from "../assets/styles/dashboard.module.scss";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { CustomButton, CustomLink, InputText } from "../shared_ui_components";
import { GlobalContext } from "../context/GlobalContext";
import LogOutDialog from "./LogOutDialog";
import Shimmer from "./Shimmer";
import { getUserInfo } from "../../src/utils";
import {  Dialog } from "@material-ui/core";
import AddOrgDialog from "./AddOrgDialog";

export default function PartnerHeader(props) {
  const { id, _id } = useParams();
  const userInfoRole = getUserInfo();
  const history = useHistory();
  let location = useLocation();
  let recentlyVisitedOrganisation = JSON.parse(localStorage.getItem("recentlyVisitedOrgs"));
  const {
    state: { orgListNames },
  } = useContext(GlobalContext);
  const [newOrg, setNewOrg] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorOrgEl, setAnchorOrgEl] = useState(null);
  const [orgNames, setOrgNames] = useState([]);
  const [openLogPop, setLogPop] = useState(false);
  const [recentlyVisitedOrgs, setRecentlyVisitedOrgs] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [searchedOrgNames, setSearchedOrgNames] = useState([]);
  const [searchedRecentOrg, setSearchedRecentOrg] = useState([]);

  useEffect(() => {
    if (location.pathname === "/activate") {
      setAnchorOrgEl(true);
    }
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOrgClick = (event) => {
    setAnchorOrgEl(event.currentTarget);
  };
  const handleOrgClose = () => {
    if (location.pathname === "/activate") {
      setAnchorOrgEl(null);
      setSearchString("");
      history.push("/home");
    } else {
      setAnchorOrgEl(null);
      setSearchString("");
    }
  };

  const goToRequestQuote = () => {
    history.push("/request-quote");
  };

  const goToHome = () => {
    history.push("/home");
  };

  const showOrganisationName = [
    `/${id}/overview`,
    `/${id}/devices`,
    `/${id}/devices/${_id}`,
    `/${id}/workspaces`,
    `/${id}/workspaces/${_id}`,
    `/${id}/orders`,
    `/${id}/quotations`,
    `/${id}/quotations/${_id}`,
    `/${id}/place-order`,
    `/${id}/users`,
    `/${id}/settings`,
    `/${id}/reports`,
    `/${id}/current-meetings`,
    `/${id}/reports/meetingDetails`,
    `/${id}/resources`,
    `/${id}/deployments`,
    `/${id}/deployments/${_id}`,
    "/dashboard",
    "/device-info",
  ];

  const userInfo = JSON.parse(localStorage.getItem("userInfo"))
    ? JSON.parse(localStorage.getItem("userInfo")).userData
    : "";
  //console.log(userInfo);

  useEffect(() => {
    const data = orgListNames.map((name) => {
      return { name: name.orgName, id: name._id };
    });
    setOrgNames(data);
  }, [orgListNames]);

  useEffect(() => {
    if (recentlyVisitedOrganisation && recentlyVisitedOrganisation.length) {
      setRecentlyVisitedOrgs(recentlyVisitedOrganisation.slice(0, 2));
    }
  }, [JSON.stringify(recentlyVisitedOrganisation)]);

  const handleLogout = () => {
    // localStorage.clear();
    localStorage.removeItem("userInfo");
    localStorage.removeItem("recentlyVisitedOrgs");
    history.push("/signin");
  };

  const handleCancel = () => {
    setLogPop(false);
    handleClose();
  };
  const handleClickOpen = () => {
    setNewOrg(true);
  };
  const handleClickClose = () => {
    setNewOrg(false);
  };
  const handleSearch = (val) => {
    let str = val.toLowerCase();
    setSearchString(str);
    let orgs = orgNames.filter((item) => item.name.toLowerCase().includes(str));
    setSearchedOrgNames(
      orgs.filter(
        (_org) =>
          !recentlyVisitedOrgs
            .map((rOrg) => {
              return rOrg._id;
            })
            .includes(_org.id)
      )
    );
    setSearchedRecentOrg(recentlyVisitedOrgs.filter((item) => item.orgName.toLowerCase().includes(str)));
  };
  const handleAllSearch = (val) => {
    setSearchString(val.toLowerCase());
    setSearchedOrgNames(orgNames.filter((item) => item.name.toLowerCase().includes(val.toLowerCase())));
  };

  return (
    <div>
      <div className="PartnerHeader">
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item md={5} container direction="row" justifyContent="flex-start" alignItems="stretch">
            <div className="headerLogoWrapper flexCentered">
              <Link to="/">
                <img src={imageBasePath + "logo.svg"} alt="Letsving" />
              </Link>
            </div>
            <div className="headerMenu ">
              <List className="menuItems">
                <ListItem
                  className={`menuItem 
                    ${location.pathname === "/home" ? "active" : ""}`}
                  onClick={() => goToHome()}
                >
                  Home
                </ListItem>
                <ListItem
                  className={`menuItem 
                    ${location.pathname === "/dashboard" ? "active" : ""}`}
                  aria-controls="organization-dropdown-menu"
                >
                  <span onClick={handleOrgClick} style={{ height: "60px", lineHeight: "60px" }}>
                    Organisation
                  </span>
                  <span onClick={handleOrgClick} className="menuItemDropdown">
                    <img src={imageBasePath + "dashboard_dropdown.svg"} alt="" />
                  </span>
                  <Menu
                    id="organization-dropdown-menu"
                    className="organisationDropdownMenu"
                    anchorOrgEl={anchorOrgEl}
                    keepMounted
                    open={Boolean(anchorOrgEl)}
                    onClose={handleOrgClose}
                  >
                    {location.pathname != "/activate" && (
                      <>
                        <img
                          className="DropdownPointerImg"
                          src={imageBasePath + "dropdown-pointer.svg"}
                          alt="dropdown"
                        />
                        <div className="organisationDropdownSearchInput">
                          <InputText
                            onChange={(e) => {
                              location.pathname === "/activate"
                                ? handleAllSearch(e.target.value)
                                : handleSearch(e.target.value);
                            }}
                            placeholder="Search here"
                          />
                          <img src={imageBasePath + "search_icon.svg"} alt="" />
                        </div>
                      </>
                    )}
                    {location.pathname === "/activate" ? (
                      orgNames.length === 0 ? (
                        <Shimmer type="activate" />
                      ) : (
                        <>
                          <img
                            className="DropdownPointerImg"
                            src={imageBasePath + "dropdown-pointer.svg"}
                            alt="dropdown"
                          />
                          <div className="organisationDropdownSearchInput">
                            <InputText
                              onChange={(e) => {
                                location.pathname === "/activate"
                                  ? handleAllSearch(e.target.value)
                                  : handleSearch(e.target.value);
                              }}
                              placeholder="Search here"
                            />
                            <img src={imageBasePath + "search_icon.svg"} alt="" />
                          </div>
                          <div className="companyListWrapper">
                            <div className="companyAllListWrapper">
                              <div className="companyAllTitleWrapper">
                                <p className="companyAllListTitle">
                                  All Organisations (<span>{orgNames.length}</span>)
                                </p>
                                <Link to="/all-organisation" className="companyAllViewLink">
                                  View all
                                </Link>
                              </div>
                              {searchString && searchedOrgNames.length ? (
                                <>
                                  {searchedOrgNames.map((orgName, key) => (
                                    <Link
                                      to={`/${orgName.id}/devices`}
                                      key={orgName.id}
                                      onClick={handleOrgClose}
                                    >
                                      <div className="companyAllListBlk">
                                        <img src={imageBasePath + "organisation-icon.svg"} alt="" />
                                        <p className="companyAllListName">{orgName.name}</p>
                                      </div>
                                    </Link>
                                  ))}
                                </>
                              ) : searchString && !searchedOrgNames.length ? (
                                <a>
                                  <div className="companyAllListBlk">
                                    <img src={imageBasePath + "organisation-icon.svg"} alt="" />
                                    <p className="companyAllListName">No result found</p>
                                  </div>
                                </a>
                              ) : (
                                orgNames.map((orgName, key) => (
                                  <Link
                                    to={`/${orgName.id}/devices`}
                                    key={orgName.id}
                                    onClick={handleOrgClose}
                                  >
                                    <div className="companyAllListBlk">
                                      <img src={imageBasePath + "organisation-icon.svg"} alt="" />
                                      <p className="companyAllListName">{orgName.name}</p>
                                    </div>
                                  </Link>
                                ))
                              )}
                            </div>
                          </div>
                        </>
                      )
                    ) : (
                      <div className="companyListWrapper">
                        {recentlyVisitedOrgs && recentlyVisitedOrgs.length ? (
                          <div className="companyRecentListWrapper">
                            <p className="companyRecentListTitle">Recently Visited</p>
                            {searchString && searchedRecentOrg.length ? (
                              <>
                                {searchedRecentOrg.map((item, key) => (
                                  <Link
                                    to={`/${item._id}/overview`}
                                    key={item.id}
                                    onClick={handleOrgClose}
                                  >
                                    <div className="companyRecentListBlk">
                                      <img src={imageBasePath + "organisation-icon.svg"} alt="" />
                                      <p
                                        className="companyRecentListName"
                                        style={{ color: item._id === id && "blue" }}
                                      >
                                        {item.orgName}
                                      </p>
                                    </div>
                                  </Link>
                                ))}
                              </>
                            ) : searchString && !searchedRecentOrg.length ? (
                              <a>
                                <div className="companyRecentListBlk">
                                  <img src={imageBasePath + "organisation-icon.svg"} alt="" />
                                  <p className="companyRecentListName">No result found</p>
                                </div>
                              </a>
                            ) : (
                              recentlyVisitedOrgs.map((item, key) => (
                                <Link
                                  to={`/${item._id}/overview`}
                                  key={item.id}
                                  onClick={handleOrgClose}
                                >
                                  <div className="companyRecentListBlk">
                                    <img src={imageBasePath + "organisation-icon.svg"} alt="" />
                                    <p
                                      className="companyRecentListName"
                                      style={{ color: item._id === id ? "blue" : "" }}
                                    >
                                      {item.orgName}
                                    </p>
                                  </div>
                                </Link>
                              ))
                            )}
                          </div>
                        ) : null}
                        <div className="companyAllListWrapper">
                          {location.pathname === "/activate" ? (
                            <div className="companyAllTitleWrapper">
                              <p className="companyAllListTitle">
                                All Organisations (<span>{orgNames.length}</span>)
                              </p>
                              <Link to="/all-organisation" className="companyAllViewLink">
                                View all
                              </Link>
                            </div>
                          ) : (
                            <div className="companyAllTitleWrapper">
                              <p className="companyAllListTitle">
                                All Organisations (
                                <span>
                                  {searchString
                                    ? searchedOrgNames.length
                                    : orgNames.filter(
                                        (_org) =>
                                          !recentlyVisitedOrgs
                                            .map((rOrg) => {
                                              return rOrg._id;
                                            })
                                            .includes(_org.id)
                                      ).length}
                                </span>
                                )
                              </p>
                              <Link to="/all-organisation" className="companyAllViewLink">
                                View all
                              </Link>
                            </div>
                          )}

                          {searchString && searchedOrgNames.length ? (
                            <>
                              {searchedOrgNames.map((orgName, key) => (
                                <Link
                                  to={
                                    location.pathname === "/activate"
                                      ? `/${orgName.id}/devices`
                                      : `/${orgName.id}/overview`
                                  }
                                  key={orgName.id}
                                  onClick={handleOrgClose}
                                >
                                  <div className="companyAllListBlk">
                                    <img src={imageBasePath + "organisation-icon.svg"} alt="" />
                                    <p className="companyAllListName">{orgName.name}</p>
                                  </div>
                                </Link>
                              ))}
                            </>
                          ) : searchString && !searchedOrgNames.length ? (
                            <a>
                              <div className="companyAllListBlk">
                                <img src={imageBasePath + "organisation-icon.svg"} alt="" />
                                <p className="companyAllListName">No result found</p>
                              </div>
                            </a>
                          ) : (
                            orgNames.map((orgName, key) =>
                              recentlyVisitedOrgs.filter((item) => item._id === orgName.id).length ? null : (
                                <Link
                                  to={
                                    location.pathname === "/activate"
                                      ? `/${orgName.id}/devices`
                                      : `/${orgName.id}/overview`
                                  }
                                  key={orgName.id}
                                  onClick={handleOrgClose}
                                >
                                  <div className="companyAllListBlk">
                                    <img src={imageBasePath + "organisation-icon.svg"} alt="" />
                                    <p className="companyAllListName">{orgName.name}</p>
                                  </div>
                                </Link>
                              )
                            )
                          )}
                        </div>
                      </div>
                    )}
                    {userInfo?.role?.responsibilities?.createOrg
                      ? orgNames.length > 0 && (
                          <>
                            <div className="addOrgButtonWrapper">
                              <CustomLink
                                className="addButton label"
                                text="Add Organisation"
                                leftIcon={imageBasePath + "Add-Org.svg"}
                                to="/add-organisation"
                              />
                            </div>
                          </>
                        )
                      : null}
                  </Menu>
                </ListItem>
                <ListItem>
                   {userInfoRole?.userData?.role?.roleName === "vingPartner" ? 
                 <CustomButton
                 onClick={handleClickOpen}
                 children="Create Org"
                 className="requestButton  primaryBtn"
               />
              : ''}
               <Dialog open={newOrg} onClose={handleClickClose}>
                          <AddOrgDialog
                            handleClose={handleClickClose}
                          />
                        </Dialog> 
                </ListItem>
                {/* <ListItem className="menuItem">Store </ListItem> */}
              </List>
            </div>
          </Grid>
          <Grid item md={4} container direction="row" justifyContent="flex-end" alignItems="stretch">
            <div className="headerRightBlk flexCentered">
              <List className="listInline rightBlkItems">
                {/* {userInfo?.role?.responsibilities?.reqQuote ? (
                  <ListItem className="listInlineItem">
                    <CustomButton
                      onClick={() => goToRequestQuote()}
                      children="Request quote"
                      className="requestButton  primaryBtn"
                    />
                  </ListItem>
                ) : null} */}

                {/* <ListItem className="listInlineItem">
                  <button>
                    <img
                      className="defaultImg"
                      src={imageBasePath + "dashboard_notification.svg"}
                      alt="Notification"
                    />
                  </button>
                </ListItem> */}
                <ListItem className="listInlineItem">
                  <div className="headerUserProfile">
                    <div className="headerProfileDropdown" onClick={handleClick}>
                      {userInfo && userInfo?.imageURL ? (
                        <img src={userInfo && userInfo?.imageURL} className="userImg" alt="avatar" />
                      ) : (
                        <Avatar className="avatar">
                          {userInfo && userInfo?.fullName
                            ? userInfo?.fullName.substring(0, 1)
                            : userInfo && userInfo?.email
                            ? userInfo?.email?.substring(0, 1)
                            : ""}
                        </Avatar>
                      )}
                      <p className="userName paragraph">
                        <span>{userInfo && userInfo?.fullName ? userInfo?.fullName : ""}</span>
                        <img src={imageBasePath + "dropdown_icon.svg"} alt="dropdown" />
                      </p>
                    </div>
                    <Menu
                      id="simple-menu"
                      className="dropdownMenu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem className="dropdownMenuItem supportText">
                        <Link to="/account-settings">
                          <img src={imageBasePath + "settings_active.svg"} alt="Settings" />
                          Settings
                        </Link>
                      </MenuItem>
                      <MenuItem className="dropdownMenuItem supportText">
                        <Link onClick={() => setLogPop(true)}>
                          <img src={imageBasePath + "logout_default_icon.svg"} alt="Logout" />
                          Logout
                        </Link>
                      </MenuItem>
                    </Menu>
                    {openLogPop ? (
                      <LogOutDialog
                        text="Are you sure you want to logout?"
                        handleSuccess={handleLogout}
                        handleCancel={handleCancel}
                      />
                    ) : null}
                  </div>
                </ListItem>
              </List>
            </div>
          </Grid>
        </Grid>
      </div>
      <>
        {showOrganisationName.includes(history.location.pathname) ? (
          orgNames.length === 0 ? (
           <Shimmer type="orgName" />
          ) : (
            <div className="companyNameSection">
              <img className="" src={imageBasePath + "company_active.svg"} alt="company" />
              <p className=" companyName subheading2">{orgNames?.find((org) => org.id === id)?.name}</p>
            </div>
          )
        ) : null}

      </>
      
    </div>
  );
}
