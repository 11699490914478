import { Grid, Dialog } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styles from "../../../assets/styles/devices.module.scss";
import settingsStyles from "../../../assets/styles/orgSettings.module.scss";
import { imageBasePath } from "../../../constants";
import { CustomButton } from "../../../shared_ui_components";
import LanguageIcon from '@material-ui/icons/Language';
import { activateAndDeactivateDeviceById, resetDeviceById,selectDeviceType } from "../apiServices";
import { toastFlashMessage } from "../../../utils";
import { useParams } from "react-router";
import { Wallpapers } from "./Wallpapers";

function PreviewIsNotAvailable({ wallpaperLink }) {
  return (
    <div
      className={styles.wallpapers}
      style={{
        height: "100%",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <span style={{ fontSize: "13px" }}>Preview is not available</span>

      <a
        href={wallpaperLink}
        target="_blank"
        style={{
          fontSize: "13px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "2px",
          textDecoration: "underline",
        }}
      >
        Open link in new tab
        <div style={{ width: "14px", marginTop: "4px" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
            />
          </svg>
        </div>
      </a>
    </div>
  );
}

function CustomImage({ deviceDetails }) {
  const wallpaperLink = deviceDetails?.deviceWallpaper?.backgroundImageUrl;

  let localLink = false;
  if(wallpaperLink){
    const link = new URL(`${wallpaperLink}`);
    localLink = link?.protocol === "http:";
  }


  if (localLink) {
    return (
      <div
        style={{
          height: "100%",
          cursor: "pointer",
          border: "1px solid #e6e8e9",
          borderRadius: "8px",
          padding : "5px 5px",
          backgroundColor: "aliceblue"

        }}
      >
        <PreviewIsNotAvailable wallpaperLink={wallpaperLink} />
      </div>
    );
  }
  return (
    <img
      src={
        deviceDetails?.deviceWallpaper
          ? deviceDetails?.deviceWallpaper?.backgroundImageUrl
          : imageBasePath + "device_settings_wallpaper.svg"
      }
      alt=""
    />
  );
}


function CustomVideo({ deviceDetails }) {
  const wallpaperLink = deviceDetails?.deviceWallpaper?.backgroundImageUrl;
  let localLink = false;
  if(wallpaperLink){
    const link = new URL(`${wallpaperLink}`);
    localLink = link?.protocol === "http:";
  }

  if (localLink) {
    return (
      <div
        style={{
          height: "100%",
          cursor: "pointer",
          border: "1px solid #e6e8e9",
          borderRadius: "8px",
          padding: "5px 5px",
          backgroundColor: "aliceblue"
        }}
      >
        <PreviewIsNotAvailable wallpaperLink={wallpaperLink} />
      </div>
    );
  }
  return (
    <video src={deviceDetails?.deviceWallpaper?.backgroundImageUrl} alt="" />
  );
}



export default function DeviceSettings(props) {
  const { deviceDetails, handleDeviceDetail } = props;
  const { id, _id } = useParams();
  const [loader, setLoader] = useState(false);
  const [resetLoader, setResetLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [deviceType, setDeviceType] = useState("VING_ROOM");

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(()=>{
    setDeviceType(deviceDetails.deviceType)
  },[])
  const handleEditWallpaper = (value) => {
    setOpen(true);
  };

  const handleResetDevice = () => {
    setResetLoader(true);
    resetDeviceById({
      deviceId: deviceDetails._id,
      macId: deviceDetails.macId,
    }).then((response) => {
      if (response.status) {
        setResetLoader(false);
        handleDeviceDetail();
        toastFlashMessage(`Device reset successfully`, "success");
      } else {
        setResetLoader(false);
        toastFlashMessage(`Something went wrong while resetting the device`, "error");
      }
    });
  };

   const chooseDeviceType = (e) => {
    selectDeviceType({
      deviceId: deviceDetails._id,
      deviceType: e.target.value,
    }).then((response) => {
    setDeviceType(response.res);
      if (response.status) {
        toastFlashMessage(`DeviceType changed successfully`, "success");
      } else {
        toastFlashMessage(
          `Something went wrong while changing the deviceType`,
          "error"
        );
      }
    });
  };
  const handleActivateDeactivateDevice = () => {
    setLoader(true);
    activateAndDeactivateDeviceById({
      deviceId: deviceDetails._id,
      type: `${deviceDetails.deviceStatus === "Active" ? "deactivate" : "activate"}`,
    }).then((response) => {
      if (response.status) {
        setLoader(false);
        handleDeviceDetail();
        toastFlashMessage(
          `Device ${deviceDetails.deviceStatus === "Active" ? "deactived" : "activated"} successfully`,
          "success"
        );
      } else {
        setLoader(false);
        toastFlashMessage(
          `Something went wrong while ${
            deviceDetails.deviceStatus === "Active" ? "deactivating" : "activating"
          } the device`,
          "error"
        );
      }
    });
  };

  const closeDialog = (value) => {
    setOpen(value);
  };

  return (
    <>
      <div className={styles.deviceSettingsWrapper}>
        <div className={styles.deviceSettingsHeading}>
          <h3 className={styles.deviceSettingsTitle + " paragraph "}>Device Settings</h3>
        </div>
        <div className={styles.deviceSettingsRow}>
          <Grid container spacing={2}>
             <Grid item md={7}>
              <h4 className={styles.deviceSettingsLeftTitle + " supportText "}>
                Device Type
              </h4>
            </Grid>
            <Grid item md={5}>
              <div className={styles.deviceSettingsRightBlk}>
                <form>
                  <select
                    name="deviceType"
                    onChange={chooseDeviceType}
                    className={styles.dropdownBtn + " outlinedBtn "}
                    value={deviceType}
                  >
                    <option value="VING_ROOM"> Ving Room</option>
                    <option value="SHARE_ROOM">Share Room</option>
                    <option value="VING_SIGNAGE">Ving Signage</option>
                  </select>
                </form>
              </div>
            </Grid>
            <Grid item md={7}>
              <div className={styles.deviceSettingsLeftBlk}>
                <h4 className={styles.deviceSettingsLeftTitle + " supportText "}>Wallpaper Configuration</h4>
                <p className={styles.deviceSettingsLeftSubText + " supportText "}>
                  This action will change the wallpaper of the device
                </p>
              </div>
            </Grid>
            <Grid item md={5}>
              { deviceDetails?.deviceWallpaper?.wallpaperType !=="appLink"?<div className={styles.deviceSettingsRightBlk}>
                    {deviceDetails?.deviceWallpaper?.backgroundImageUrl?.includes(".mp4") ? (
                      <CustomVideo deviceDetails={deviceDetails}/>
                    ):(
                      <CustomImage deviceDetails={deviceDetails} />
                    )}
                <CustomButton
                  className={styles.changeWallpaperBtn + " outlinedBtn "}
                  children="Change"
                  onClick={() => handleEditWallpaper(1)}
                />
              </div>:
              <div className={styles.appLinkWallpaper}>
                <div className={styles.appLinkInnerContainer}>
                  <div className={styles.appLinkIconContainer}>
                    <LanguageIcon />
                    <p style={{fontSize:"14px"}}>App Link</p>
                  </div>
                 <div>
                  <a href={deviceDetails?.deviceWallpaper?.backgroundImageUrl} target="_blank"
                  className={styles.appLinkText}>{deviceDetails?.deviceWallpaper?.backgroundImageUrl}</a>
                </div>
             </div>
                <CustomButton 
                 className={styles.changeWallpaperBtn + " outlinedBtn " } 
                 children="Change" 
                 onClick={()=>handleEditWallpaper(1)}/>
              </div>}
            </Grid>
          </Grid>
        </div>
        {/* <div className={styles.deviceSettingsRow}>
          <Grid container spacing={2}>
            <Grid item md={7}>
              <div className={styles.deviceSettingsLeftBlk}>
                <h4 className={styles.deviceSettingsLeftTitle + " supportText "}>Welcome Display</h4>
                <p className={styles.deviceSettingsLeftSubText + " supportText "}>
                  This action will change the welcome message on the device
                </p>
              </div>
            </Grid>
            <Grid item md={5}>
              <div className={styles.deviceSettingsRightBlk}>
                <CustomButton
                  className={styles.editBtn + " outlinedBtn "}
                  children="Edit"
                  onClick={() => handleEditWallpaper(2)}
                />
              </div>
            </Grid>
          </Grid> 
       </div> */}
        <div className={styles.deviceSettingsRow}>
          <Grid container spacing={2}>
            <Grid item md={7}>
              <div className={styles.deviceSettingsLeftBlk}>
                <h4 className={styles.deviceSettingsLeftTitle + " supportText "}>Reset Device</h4>
                <p className={styles.deviceSettingsLeftSubText + " supportText "}>
                  This action will change the Ving Box settings to default
                </p>
              </div>
            </Grid>
            <Grid item md={5}>
              <div className={styles.deviceSettingsRightBlk}>
                <CustomButton
                  className={`${styles.resetBtn} ${resetLoader ? "disabledBtn" : ""} outlinedBtn `}
                  children={`${resetLoader ? "Resetting..." : "Reset"}`}
                  onClick={handleResetDevice}
                />
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={styles.deviceSettingsRow}>
          <Grid container spacing={2}>
            <Grid item md={7}>
              <div className={styles.deviceSettingsLeftBlk}>
                <h4 className={styles.deviceSettingsLeftTitle + " supportText "}>
                  {deviceDetails.deviceStatus === "Active" ? "Deactivate device" : "Activate device"}
                </h4>
                <p className={styles.deviceSettingsLeftSubText + " supportText "}>
                  {/* `This action will make this Ving Box inactive` */}
                  {`This actiion will make this Ving Box ${
                    deviceDetails.deviceStatus === "Active" ? "inactive" : "active"
                  }`}
                </p>
              </div>
            </Grid>
            <Grid item md={5}>
              <div className={styles.deviceSettingsRightBlk}>
                <CustomButton
                  className={`${styles.resetBtn} ${loader ? "disabledBtn" : ""} ${
                    deviceDetails.deviceStatus === true ? "dangerBtn" : ""
                  } outlinedBtn `}
                  children={`${deviceDetails.deviceStatus === "Active" ? "Deactivate" : "Activate"}`}
                  onClick={handleActivateDeactivateDevice}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      {open && (
        <Dialog open={open} onClose={handleClose} className="wallpaperDialogWrapper" maxWidth="sm">
          <div className={settingsStyles.settingsWrapper} style={{ padding: 0, width: "600px" }}>
            <Wallpapers
              open={open}
              deviceId={deviceDetails._id}
              closeDialog={closeDialog}
              handleDeviceDetail={handleDeviceDetail}
            />
          </div>
        </Dialog>
      )}
    </>
  );
}
